<template>
  <div class="text-center">
    <v-card v-if="form !== null" style="position:relative;">
      <v-card
        flat
        style="
                z-index: 2;
                position: sticky;
                top: 0;
                background: white;
                border-bottom: 1.5px solid indigo;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
      >
        <div
          style="
                  display: flex;
                  jsutify-content: center;
                  align-items: center;
                  margin-left: 10px;
                "
        >
          <v-toolbar-title class="overline"
            ><p
              style="font-size: 18px; margin: 0; font-weight: bold;color:indigo;"
            >
              Detail Pengelolaan Aset
            </p>
          </v-toolbar-title>
        </div>
        <div>
          <router-link to="/asset-management/aset-kepemilikan">
            <v-btn
              outlined
              rounded
              color="red"
              class="red--text font-weight-bold ma-2"
            >
              Exit
            </v-btn>
          </router-link>
        </div>
      </v-card>

      <v-row no-gutters style="padding: 15px;">
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                class="text-left"
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Nama Aset
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field :value="form.name" dense outlined readonly />
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col
        ><v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                class="text-left"
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Kode Aset
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field :value="form.code" dense outlined readonly />
            </v-col>
          </div>
        </v-col>

        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                class="text-left"
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Jumlah Stock
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field :value="form.total_stock" dense outlined readonly />
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col>
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                class="text-left"
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Kategori Aset
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="form.category !== null ? form.category.name : '-'"
                dense
                outlined
                readonly
              />
            </v-col>
          </div>
        </v-col>

        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                class="text-left"
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Current Stock
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field
                :value="form.current_stock"
                dense
                outlined
                readonly
                type="number"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col>
        <lot-management
          :assetDetail="{
            id: asset_id,
            code: asset_code
          }"
          :lot="detailLot"
          @loadPage="detail"
        />
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  name: 'Asset-Detail',
  components: {
    LotManagement: () => import('./components/LotList')
  },
  data() {
    return {
      asset: buildType.apiURL('asset'),
      eSanqua: buildType.apiURL('esanqua'),
      dialog: false,
      form: null,
      loading: false,
      detailLot: null,
      asset_id: null,
      asset_code: null
    }
  },
  computed: {
    ...mapGetters(['getParamAsset'])
  },
  mounted() {
    this.detail()
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    async detail() {
      this.detailLot = null
      await axios
        .get(`${this.asset}asset/detail/${this.getParamAsset.id}`)
        .then(res => {
          if (res.data.status_code !== '-99') {
            console.log(res)
            this.form = res.data.data
            this.asset_id = res.data.data.id
            this.asset_code = res.data.data.code
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })

      console.log(this.getParamAsset.id)
      await axios
        .get(`${this.asset}asset/lot/list?asset=${this.getParamAsset.id}`)
        .then(res => {
          if (res.data.status_code !== '-99') {
            console.log(res)
            this.detailLot = res.data.data
          }
          // else {
          //   this.showMsgDialog('warning', res.data.status_msg, false)
          // }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    }
  }
}
</script>
<style lang="scss" scoped></style>
